import React, { createContext, useEffect, useReducer } from 'react';
import { batch, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';

import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import useSettings from 'src/hooks/useSettings';

import { clearCheckIns } from 'src/slices/checkins';
import { clearGroups } from 'src/slices/group';
import { clearLocations } from 'src/slices/location';
import { clearParticipants } from 'src/slices/participant';
import { clearContests, setSelectedContest } from 'src/slices/contest';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = token => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);

  const expires = moment(decoded.exp * 1000);
  return moment().isBefore(expires);
};

const setSession = tokenData => {
  if (tokenData) {
    localStorage.setItem('accessToken', tokenData.access_token);
    localStorage.setItem('refreshToken', tokenData.refresh_token);
    axios.defaults.headers.common.Authorization = `Bearer ${tokenData.access_token}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { restoreDefaultSettings } = useSettings();
  const reduxDispatch = useDispatch();

  const login = async (username, password) => {
    const response = await axios.post('/user_tokens', { username, password });

    const { data } = response.data;
    const user = _.mapKeys(data.user, (value, key) => _.camelCase(key));
    user.isRoot = data.is_root;

    setSession(data);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });

    return response;
  };

  const logout = () => {
    setSession(null);
    restoreDefaultSettings();

    batch(() => {
      reduxDispatch(clearCheckIns());
      reduxDispatch(clearGroups());
      reduxDispatch(clearLocations());
      reduxDispatch(clearParticipants());
      reduxDispatch(clearContests());
      reduxDispatch(setSelectedContest(null));
    });

    dispatch({ type: 'LOGOUT' });
  };

  const getAccessToken = async refreshToken => {
    const response = await axios.post('/user_tokens', {
      refresh_token: refreshToken
    });
    const { data } = response.data;

    return data;
  };

  const getUser = async () => {
    const query = `
    {
      user{
        id,
        isRoot,
        firstName,
        lastName,
        profileImage{
          image{
            thumbnail,
            small
          }
        }
      }
    }
  `;
    const response = await axios.post('/g', {
      query
    });

    const { user } = response.data.data;

    dispatch({
      type: 'INITIALISE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (
          accessToken &&
          !isValidToken(accessToken) &&
          refreshToken &&
          isValidToken(refreshToken)
        ) {
          const data = await getAccessToken(refreshToken);
          setSession(data);

          await getUser();
        } else if (accessToken && isValidToken(accessToken)) {
          const tokenData = {
            access_token: accessToken,
            refresh_token: refreshToken
          };

          setSession(tokenData);

          await getUser();
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
