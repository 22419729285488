import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  contests: [],
  selectedContest: null,
  isFetching: false
};

const slice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    getContests(state, action) {
      const { contests } = action.payload;

      contests.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }

        if (a.id > b.id) {
          return -1;
        }

        return 0;
      });

      state.contests = contests;
    },
    setContests(state, action) {
      const { contests } = action.payload;

      state.contests = contests;
    },
    setSelectedContest(state, action) {
      const { contest } = action.payload;

      state.selectedContest = contest;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getContests = () => async dispatch => {
  const query = `
    {
      admin{
        contests{
          id,
          accessLevel,
          collectionId,
          mainColor,
          name,
          nameId,
          active,
          dateFrom,
          dateTo,
          latitude,
          longitude,
          contestOrganizer{
            id,
            name
          }
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post('/g', { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getContests(response.data.data.admin));
};

export const setSelectedContest = contest => async dispatch => {
  dispatch(slice.actions.setSelectedContest({ contest }));
};

export const clearContests = () => async dispatch => {
  dispatch(slice.actions.setContests({ contests: [] }));
};
