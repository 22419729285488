import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const defaultFilterValues = {
  dateFrom: null,
  dateTo: null,
  dateFor: '',
  numberOfCheckIns: {
    comparator: '=',
    value: null
  },
  differentLocations: {
    comparator: '=',
    value: null
  },
  numberOfPoints: {
    comparator: '=',
    value: null
  },
  showNonMembers: false,
  showMembers: false,
  showCompanyTeams: false,
  showFriendTeams: false,
  showFamilyTeams: false,
  showSinglePerson: false,
  isFilterActive: false
};

const initialState = {
  participants: [],
  selectedParticipant: null,
  filterValues: { ...defaultFilterValues },
  isFetching: false
};

const slice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    getParticipants(state, action) {
      const { participations } = action.payload;

      // Hack to make CSV export work.
      // If the nested object does not exist on the 'participation' attribute, the export will fail.
      if (participations) {
        participations.forEach(x => {
          if (!x.ranking) {
            x.ranking = {};
          }

          if (!x.group) {
            x.group = {};
          }
        });
      }

      state.participants = participations;
    },
    setParticipants(state, action) {
      const { participants } = action.payload;

      state.participants = participants;
    },
    setSelectedParticipant(state, action) {
      const { participant } = action.payload;

      state.selectedParticipant = participant;
    },
    setFilterValues(state, action) {
      const { filterValues } = action.payload;
      const updated = { ...state.filterValues, ...filterValues };

      state.filterValues = updated;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const setSelectedParticipant = participant => async dispatch => {
  dispatch(slice.actions.setSelectedParticipant({ participant }));
};

export const getParticipants = contestId => async dispatch => {
  const query = `
    {
      participations{
        id,
        insertedAt,
        user{
          id,
          firstName,
          lastName,
          email,
          birthYear,
          phone,
          profileImage{
            image{
              thumbnail
            }
          }
        },
        ranking{
          ranking,
          totalPoints,
          stats{
            checkInsCount,
            checkInsAtUniqueLocationsCount
          },
        },
        group{
          id,
          name,
          groupType {
            id,
            name
          }
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getParticipants(response.data.data));
};

export const setFilterValues = filterValues => async dispatch => {
  dispatch(slice.actions.setFilterValues({ filterValues }));
};

export const resetFilterValues = () => async dispatch => {
  dispatch(
    slice.actions.setFilterValues({ filterValues: defaultFilterValues })
  );
};

export const clearParticipants = () => async dispatch => {
  dispatch(slice.actions.setParticipants({ participants: [] }));
};

export default slice;
