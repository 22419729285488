import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const local = 'http://trimpoeng-api-server.test:4849/api';
const prod = 'https://api.trimpoeng.no/api';

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? prod : local
});

const isValidToken = token => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);

  const expires = moment(decoded.exp * 1000);
  return moment().isBefore(expires);
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await axiosInstance.post('/user_tokens', {
    refresh_token: refreshToken
  });

  const { data } = response.data;
  let accessToken = null;

  if (data) {
    accessToken = data.access_token;
    localStorage.setItem('accessToken', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  return accessToken;
};

axiosInstance.interceptors.response.use(
  res => res,
  async err => {
    if (axios.isCancel(err)) {
      throw new Error('canceled');
    } else if (window.location.pathname === '/login') {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.status >= 400
      ) {
        throw new Error(err.response.data.message);
      }

      throw err;
    } else {
      const originalRequest = err.config;

      // Redirect to login if the error occured while calling /user_tokens
      // from another page than /userRequests.
      // This is most likely due to expired or missing tokens
      if (
        originalRequest.url === '/user_tokens' &&
        !window.location.pathname.startsWith('/userRequest')
      ) {
        const loginPath = window.location.href.replace(
          window.location.pathname,
          '/login'
        );

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        window.location.replace(loginPath);
      }

      const currentToken = originalRequest?.headers?.Authorzation
        ? originalRequest.headers.Authorization.replace('Bearer ', '')
        : '';

      // Attempt to refresh access token if we get a 401 response and the
      // token has expired
      if (
        err.response.status === 401 &&
        !isValidToken(currentToken) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const accessToken = await refreshAccessToken();
        const bearer = `Bearer ${accessToken}`;

        originalRequest.headers.Authorization = bearer;
        axiosInstance.defaults.headers.common.Authorization = bearer;

        return axiosInstance(originalRequest);
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.status >= 400
      ) {
        throw new Error(err.response.data.message);
      } else {
        throw err;
      }
    }
  }
);

export default axiosInstance;
