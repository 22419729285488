import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import RootGuard from './components/RootGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/userRequest',
    component: lazy(() => import('src/views/userRequest/MainView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/contest/locations',
        component: lazy(() => import('src/views/location/LocationListView'))
      },
      {
        exact: true,
        path: '/app/contest/locations/:locationId',
        component: lazy(() => import('src/views/location/LocationDetailsView'))
      },
      {
        exact: true,
        path: '/app/contest/locations/:locationId/:type',
        component: lazy(() =>
          import('src/views/location/LocationCreateEditView')
        )
      },
      {
        exact: true,
        path: '/app/contest/participants',
        component: lazy(() =>
          import('src/views/participant/ParticipantListView')
        )
      },
      {
        exact: true,
        path: '/app/contest/participants/:participantId',
        component: lazy(() =>
          import('src/views/participant/ParticipantDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/contest/participants/:participantId/:type',
        component: lazy(() =>
          import('src/views/participant/ParticipantCreateView')
        )
      },
      {
        exact: true,
        path: '/app/contest/groupTypes',
        component: lazy(() => import('src/views/groupType/GroupTypeListView'))
      },
      {
        exact: true,
        path: '/app/contest/groupTypes/:groupTypeId/:type',
        component: lazy(() =>
          import('src/views/groupType/GroupTypeCreateEditView')
        )
      },
      {
        exact: true,
        path: '/app/contest/groups',
        component: lazy(() => import('src/views/group/GroupListView'))
      },
      {
        exact: true,
        path: '/app/contest/groups/:groupId',
        component: lazy(() =>
          import('src/views/group/GroupParticipantsListView')
        )
      },
      {
        exact: true,
        path: '/app/contest/groups/:groupId/:type',
        component: lazy(() => import('src/views/group/GroupCreateEditView'))
      },
      {
        exact: true,
        guard: RootGuard,
        path: '/app/contest/new',
        component: lazy(() => import('src/views/contest'))
      },
      {
        exact: true,
        path: '/app/contest/checkInMap',
        component: lazy(() => import('src/views/errors/NotMadeView'))
      },
      {
        exact: true,
        path: '/app/contest/transactions/:transactionId?',
        component: lazy(() => import('src/views/transactions/index'))
      },
      {
        exact: true,
        path: '/app/homepage',
        component: lazy(() => import('src/views/homepage'))
      },
      {
        exact: true,
        path: '/app/homepage_new',
        component: lazy(() => import('src/views/homepageNew'))
      },
      {
        exact: true,
        path: '/app/contest',
        component: () => <Redirect to="/app/contest/locations" />
      },
      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('src/views/settings/SettingsEditView'))
      },
      {
        exact: true,
        path: '/app/settings/adminUsers',
        component: lazy(() => import('src/views/settings/AdminUsersListView'))
      },
      {
        exact: true,
        path: '/app/settings/sms',
        component: lazy(() => import('src/views/errors/NotMadeView'))
      },
      {
        exact: true,
        path: '/app/archive',
        component: lazy(() => import('src/views/errors/NotMadeView'))
      },
      {
        exact: true,
        path: '/app/help',
        component: lazy(() => import('src/views/errors/NotMadeView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/search',
        component: lazy(() => import('src/views/rootSearch/RootSearchView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={`/app${window.location.search}`} />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
