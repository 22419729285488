import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as adminUserReducer } from 'src/slices/admin_user';
import { reducer as locationReducer } from 'src/slices/location';
import { reducer as checkInsReducer } from 'src/slices/checkins';
import { reducer as activitiesReducer } from 'src/slices/activities';
import { reducer as participantReducer } from 'src/slices/participant';
import { reducer as groupReducer } from 'src/slices/group';
import { reducer as groupTypeReducer } from 'src/slices/groupType';
import { reducer as contestReducer } from 'src/slices/contest';
import { reducer as transactionReducer } from 'src/slices/transaction';

const rootReducer = combineReducers({
  form: formReducer,
  adminUser: adminUserReducer,
  location: locationReducer,
  checkIns: checkInsReducer,
  activities: activitiesReducer,
  participant: participantReducer,
  group: groupReducer,
  groupType: groupTypeReducer,
  contest: contestReducer,
  transaction: transactionReducer
});

export default rootReducer;
