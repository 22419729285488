/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  DollarSign as DollarSignIcon,
  User as UserIcon,
  Users as UsersIcon,
  MapPin as MapPinIcon,
  Tool as ToolIcon,
  PlusCircle as PlusIcon,
  PieChart as PieChartIcon,
  Send as SendIcon,
  Map as MapIcon,
  Layout as LayoutIcon,
  Archive as ArchiveIcon,
  HelpCircle as HelpIcon,
  Search as SearchIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import {
  common,
  contest,
  promotion,
  settings as settingsLabels
} from 'src/assets/locale';
import NavItem from './NavItem';

const sections = [
  {
    subheader: common.general,
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/dashboard'
      },
      {
        title: common.homepage,
        icon: LayoutIcon,
        href: '/app/homepage'
      },
      {
        title: common.homepageNew,
        icon: LayoutIcon,
        href: '/app/homepage_new'
      },
      {
        title: common.search,
        icon: SearchIcon,
        href: '/app/search',
        requireRoot: true
      }
    ]
  },
  {
    subheader: contest.contest,
    items: [
      {
        title: common.create,
        icon: PlusIcon,
        href: '/app/contest/new',
        requireRoot: true
      },
      {
        title: contest.participants,
        icon: UserIcon,
        href: '/app/contest/participants'
      },
      {
        title: contest.groupTypes,
        icon: UsersIcon,
        href: '/app/contest/groupTypes'
      },
      {
        title: contest.teams,
        icon: UsersIcon,
        href: '/app/contest/groups'
      },
      {
        title: contest.location,
        icon: MapPinIcon,
        href: '/app/contest/locations'
      },
      {
        title: contest.transactions,
        icon: DollarSignIcon,
        href: '/app/contest/transactions'
      },
      {
        title: contest.checkInMap,
        icon: MapIcon,
        href: '/app/contest/checkInMap'
      }
    ]
  },
  {
    subheader: common.settings,
    items: [
      {
        title: common.settings,
        icon: ToolIcon,
        href: '/app/settings'
      },
      {
        title: settingsLabels.adminUsers,
        icon: UsersIcon,
        href: '/app/settings/adminUsers'
      },
      {
        title: settingsLabels.automaticSMS,
        icon: SendIcon,
        href: '/app/settings/sms'
      }
    ]
  },
  {
    subheader: common.other,
    items: [
      {
        title: common.archive,
        icon: ArchiveIcon,
        href: '/app/archive'
      },
      {
        title: common.help,
        icon: HelpIcon,
        href: '/app/help'
      }
    ]
  }
];

function renderNavItems({ items, pathname, isRoot, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, isRoot, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, isRoot, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          isRoot,
          items: item.items
        })}
      </NavItem>
    );
  } else if (!item.requireRoot || (item.requireRoot && isRoot)) {
    // Don't render menu item if it requires root and the user is not root
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    // cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { settings } = useSettings();

  // Hide download options until we re-enable the service worker
  const showDownloadButton = false; // localStorage.getItem('showInstallPromotion');

  const handleDownload = () => {
    const promptEvent = window.deferredPrompt;

    if (!promptEvent) {
      console.log('Unable to install');
      // The deferred prompt isn't available.
      return;
    }

    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    promptEvent.userChoice.then(() => {
      // Reset the deferred prompt variable, since
      // prompt() can only be called once.
      window.deferredPrompt = null;
    });
  };

  const avatarUrl =
    user && user.profileImage && user.profileImage.image
      ? user.profileImage.image.small
      : null;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
            {Boolean(settings && settings.selectedContest) && (
              <Box ml={2}>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/app/contest"
                  variant="h5"
                >
                  {settings.selectedContest.name}
                </Link>
                {Boolean(settings.selectedContest.contestOrganizer) && (
                  <Typography variant="body2" color="textSecondary">
                    {settings.selectedContest.contestOrganizer.name}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt="User" className={classes.avatar} src={avatarUrl} />
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="h5">
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                isRoot: user.isRoot
              })}
            </List>
          ))}
        </Box>
        {Boolean(showDownloadButton === 'true') && (
          <>
            <Divider />
            <Box p={2}>
              <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                <Typography variant="h6" color="textPrimary">
                  {promotion.tryAppText}
                </Typography>
                <Box mt={1} />
                <Button
                  onClick={handleDownload}
                  color="secondary"
                  variant="contained"
                >
                  {common.download}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
