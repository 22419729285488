import LocalizedStrings from 'react-localization';

const currentSettings = JSON.parse(localStorage.getItem('settings'));
const selectedLanguage = currentSettings?.language || 'nb';

const dashboard = new LocalizedStrings({
  nb: {
    checkInsOverTime: 'Innsjekk over tid'
  },
  en: {
    checkInsOverTime: 'Check-ins over time'
  }
});
dashboard.setLanguage(selectedLanguage);
export { dashboard };

const checkInModal = new LocalizedStrings({
  nb: {
    allCheckIns: 'Alle innsjekk',
    numberOfVisits: 'Antall besøk'
  },
  en: {
    allCheckIns: 'All check-ins',
    numberOfVisits: 'Number of visits'
  }
});
checkInModal.setLanguage(selectedLanguage);
export { checkInModal };

const common = new LocalizedStrings({
  nb: {
    actions: 'Valg',
    active: 'Aktiv',
    add: 'Legg til',
    all: 'Alle',
    archive: 'Arkiv',
    cancel: 'Avbryt',
    change: 'Endre',
    choose: 'Velg',
    close: 'Lukk',
    comment: 'Kommentar',
    confirm: 'Bekreft',
    copy: 'Kopier',
    create: 'Opprett',
    date: 'Dato',
    dates: 'Datoer',
    days: 'Dager',
    description: 'Beskrivelse',
    delete: 'Slett',
    details: 'Detaljer',
    download: 'Last ned',
    edit: 'Rediger',
    email: 'Epost',
    everything: 'Alt',
    help: 'Hjelp',
    homepage: 'Hjemmeside',
    homepageNew: 'Hjemmeside (ny)',
    hours: 'Timer',
    hoursAbbreviation: 't',
    from: 'Fra',
    general: 'Generelt',
    length: 'Lengde',
    inactive: 'Inaktiv',
    information: 'Informasjon',
    kilometers: 'Kilometer',
    language: 'Språk',
    logout: 'Logg ut',
    minutes: 'Minutter',
    move: 'Flytt',
    moved: 'Flyttet',
    name: 'Navn',
    none: 'Ingen',
    other: 'Annet',
    overview: 'Oversikt',
    password: 'Passord',
    phoneNumber: 'Telefonnummer',
    price: 'Pris',
    prices: 'Priser',
    remove: 'Fjern',
    reset: 'Tilbakestill',
    resetAlt: 'Nullstill',
    resetPassword: 'Nullstill passord',
    save: 'Lagre',
    search: 'Søk',
    settings: 'Innstillinger',
    status: 'Status',
    submitting: 'Sender forespørsel',
    supports: 'Støtter',
    theme: 'Tema',
    time: 'Tid',
    to: 'Til',
    unlimited: 'Ubegrenset',
    update: 'Oppdater',
    use: 'Bruk',
    website: 'Nettside'
  },
  en: {
    actions: 'Actions',
    active: 'Active',
    add: 'Legg til',
    all: 'All',
    archive: 'Archive',
    cancel: 'Cancel',
    change: 'Change',
    choose: 'Choose',
    close: 'Close',
    comment: 'Comment',
    confirm: 'Confirm',
    copy: 'Copy',
    create: 'Create',
    date: 'Date',
    dates: 'Dates',
    days: 'Days',
    description: 'Description',
    delete: 'Delete',
    details: 'Details',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    everything: 'Everything',
    help: 'Help',
    homepage: 'Homepage',
    homepageNew: 'Homepage (new)',
    hours: 'Hours',
    hoursAbbreviation: 'h',
    from: 'From',
    general: 'General',
    length: 'Length',
    inactive: 'Inactive',
    information: 'Information',
    kilometers: 'Kilometers',
    language: 'Language',
    logout: 'Log out',
    minutes: 'Minutes',
    move: 'Move',
    moved: 'Moved',
    name: 'Name',
    none: 'None',
    other: 'Other',
    overview: 'Overview',
    password: 'Password',
    phoneNumber: 'Phone number',
    price: 'Price',
    prices: 'Prices',
    remove: 'Remove',
    reset: 'Reset',
    resetAlt: 'Reset',
    resetPassword: 'Reset password',
    save: 'Save',
    search: 'Search',
    settings: 'Settings',
    status: 'Status',
    submitting: 'Sending request',
    supports: 'Supports',
    theme: 'Theme',
    time: 'Time',
    to: 'To',
    unlimited: 'Unlimited',
    update: 'Update',
    use: 'Use',
    website: 'Website'
  }
});
common.setLanguage(selectedLanguage);
export { common };

const contest = new LocalizedStrings({
  nb: {
    activeHelpText: 'Aktiver eller deaktiver turmålet',
    admin: 'Admin',
    allGroupTypes: 'Alle gruppetyper',
    allLocations: 'Alle turmål',
    allowPostRegistration: 'Tillat manuelle innsjekkinger fra Min Side',
    allParticipants: 'Alle deltakere',
    allTeams: 'Alle grupper',
    allowUserGroupCreation:
      'Tillat brukere å opprette grupper av denne gruppetypen',
    allowUserChildGroupCreation:
      'Tillat brukere å opprette undergrupper til grupper av denne gruppetypen',
    checkIns: 'Innsjekk',
    checkInInterval: 'Ventetid mellom innsjekk',
    checkInMap: 'Innsjekkingskart',
    checkInWith: 'Sjekk inn med',
    company: 'Bedrift',
    contest: 'Konkurranse',
    contests: 'Konkurranser',
    contestCode: 'Konkurransekode',
    contestCreated: 'Konkurransen er opprettet!',
    contestFromDateHelperText:
      'Konkurransens fra-dato dersom ingen verdi er oppgitt',
    contestToDateHelperText:
      'Konkurransens til-dato dersom ingen verdi er oppgitt',
    contestName: 'Konkurransenavn',
    contestPageCode: 'Konkurransesidekode',
    contestTerms: 'Konkurransevilkår',
    contestType: 'Konkurransetype',
    contestUrlId: 'Konkurranse URL-ID',
    coordinates: 'Koordinater',
    createContest: 'Opprett konkurranse',
    createContestSnackbarInfo:
      'Oppretting kan ta litt tid. Du kan trygt navigere bort fra denne siden mens du venter.',
    createLocation: 'Opprett turmål',
    createTeam: 'Opprett gruppe',
    daysLeft: 'Dager igjen',
    deviatingFromDate: 'Avvikende fra-dato',
    deviatingToDate: 'Avvikende til-dato',
    editLocation: 'Rediger turmål',
    elevation: 'Høydemeter',
    excelineCompanyNumber: 'Exceline bedrift nr.',
    existingContest: 'Eksisterende konkurranse',
    exportCSV: 'Eksporter CSV',
    findElevation: 'Finn høydemeter',
    groupSelfAdminDeadline: 'Siste dato for selvbetjent gruppeadministrasjon',
    intervalGymCheckIn: 'Minutter mellom gyldige gym innsjekkinger',
    latitude: 'Breddegrad',
    location: 'Turmål',
    locations: 'Turmål',
    locationCreated: 'Turmålet er opprettet',
    locationDeleteError: 'Det oppstod en feil ved sletting av turmål',
    locationDeleteSuccess: 'Turmålene er slettet',
    locationImage: 'Turmålsbilde',
    locationImageUploadWarning:
      'Du må opprette turmålet før bildet kan lastest opp',
    locationNotification: 'Varsel',
    locationNotificationDescription:
      'Du kan legge til et varsel på dette turmålet dersom det er noe viktig som turgåere bør være oppmerksomme på.',
    locationNotificationAdd: 'Legg til varsel',
    locationNotificationRemove: 'Fjern varsel',
    locationNotificationSeverity: 'Type',
    locationNotificationSeverityDescription: 'Type varsel.',
    locationNotificationSeverityTypeWarning: 'Advarsel',
    locationNotificationSeverityTypeDanger: 'Fare',
    locationNotificationRemovalConfirmation:
      'Er du sikker på at du vil fjerne varselet?',
    locationNotificationPlaceholder: 'Skriv inn varsel',
    locationPlural: 'Turmål',
    locationUpdated: 'Turmålet er oppdatert',
    longitude: 'Lengdegrad',
    gpxRoute: 'GPX rute',
    groupclasses: 'Gruppetimer',
    groups: 'Grupper',
    groupType: 'Gruppetype',
    groupTypes: 'Gruppetyper',
    groupTypeAdded: 'Gruppetypen ble opprettet',
    groupTypeUpdated: 'Gruppetypen ble oppdatert',
    groupTypeDeleted: 'Gruppetypen ble slettet',
    manualCheckinDeadline: 'Siste dato for manuelle innsjekkinger',
    map: 'Kart',
    maxAge: 'Maks alder',
    maxCheckInHelpText: 'Ubegrenset dersom ingen verdi er oppgitt',
    maxCheckInPerPerson: 'Maks innsjekk per person',
    maxCheckInPerTeam: 'Maks innsjekk per gruppe',
    maxPerDay: 'Maks per dag',
    maxPerWeek: 'Maks per uke',
    maxTeamSize: 'Maks gruppestørrelse',
    mypage: 'Min side',
    minAge: 'Min. alder',
    minTeamSize: 'Min. gruppestørrelse',
    newGroupType: 'Ny gruppetype',
    newLocation: 'Nytt turmål',
    newParticipant: 'Ny deltaker',
    newTeam: 'Ny gruppe',
    noContestTitle: 'Ingen konkurranse er valgt',
    noContestSubtitle: 'Klikk på søke-ikonet for å velge en konkurranse.',
    optimizeTeamSize: 'Optimiser gruppestørrelse',
    parentGroupType: 'Foreldregruppetype',
    parentOnly: 'Kan ikke velges (kun foreldregruppe)',
    participant: 'Deltaker',
    participants: 'Deltakere',
    placement: 'Plassering',
    points: 'Poeng',
    pointsChangeWarning: 'NB: poengendring har tilbakevirkende kraft!',
    pointsForGymCheckIn: 'Poeng for gym innsjekkinger',
    precountBooking: 'Precount av bookinger etter start dato',
    radiusForCheckIn: 'Radius for innsjekk',
    rememberCountryCode: 'NB: husk å oppgi landskode!',
    searchAdminUsers: 'Søk brukere',
    searchGroups: 'Søk grupper',
    searchGroupTypes: 'Søk gruppetyper',
    searchLocations: 'Søk turmål',
    searchParticipants: 'Søk deltakere',
    searchTransactions: 'Søk i transaksjoner',
    searchTPUsers: 'Søk etter Trimpoeng brukere',
    searchTPUsersPlaceholder: 'Skriv et navn, telefonnummer eller epostadresse',
    sendSMSEmail: 'Send SMS/Epost',
    sponsorWebsiteUrl: 'Sponsor-adresse',
    sponsorImage: 'Sponsorbilde',
    team: 'Gruppe',
    teams: 'Grupper',
    teamCreated: 'Gruppen er opprettet!',
    teamUpdated: 'Gruppen er oppdatert!',
    transaction: 'Transaksjon',
    transactions: 'Transaksjoner',
    updateLocation: 'Oppdater turmål',
    updateTeam: 'Oppdater gruppe',
    useMyPage: 'Bruk "Min side"',
    useUserIdFrom: 'Ta bruker id fra',
    validBookingStatus: 'Gyldig bookingstatus',
    waitTimeHelpText: 'Ubegrenset dersom ingen verdi er oppgitt',
    websiteType: 'Nettsidetype'
  },
  en: {
    activeHelpText: 'Activate or deactivate the location',
    admin: 'Admin',
    allGroupTypes: 'All group types',
    allLocations: 'All locations',
    allowPostRegistration: 'Allow manual check-ins from My Page',
    allParticipants: 'All participants',
    allTeams: 'All groups',
    allowUserGroupCreation: 'Allow users to create groups of this group type',
    allowUserChildGroupCreation:
      'Allow users to create child groups within this group type',
    checkIns: 'Check-ins',
    checkInInterval: 'Waiting time between check ins',
    checkInMap: 'Check-in map',
    checkInWith: 'Check-in with',
    company: 'Company',
    contest: 'Contest',
    contests: 'Contests',
    contestCode: 'Contest code',
    contestCreated: 'The contest is created!',
    contestFromDateHelperText: "The contest's from-date if no value is given",
    contestToDateHelperText: "The contest's to-date if no value is given",
    contestName: 'Contest name',
    contestPageCode: 'Contest page code',
    contestTerms: 'Contest terms',
    contestType: 'Contest type',
    contestUrlId: 'Contest URL-ID',
    coordinates: 'Coordinates',
    createContest: 'Create contest',
    createContestSnackbarInfo:
      'Creation can take some time. You can safely navigate away from this page while waiting.',
    createLocation: 'Create location',
    createTeam: 'Create group',
    daysLeft: 'Days left',
    deviatingFromDate: 'Deviating from-date',
    deviatingToDate: 'Deviating to-date',
    editLocation: 'Edit location',
    elevation: 'Elevation',
    excelineCompanyNumber: 'Exceline company number',
    existingContest: 'Existing contest',
    exportCSV: 'Export CSV',
    findElevation: 'Find elevation',
    groupSelfAdminDeadline: 'Final date for manual group administration',
    intervalGymCheckIn: 'Minutes between valid gym check-ins',
    latitude: 'Latitude',
    location: 'Location',
    locations: 'Locations',
    locationCreated: 'The location is created!',
    locationDeleteError: 'An error occured while deleting locations',
    locationDeleteSuccess: 'The locations are deleted',
    locationImage: 'Location image',
    locationImageUploadWarning:
      'You must create the location before uploading the image',
    locationNotification: 'Notification',
    locationNotificationDescription:
      "You can add a notification to this location if there's anything important you'd like the user to know about.",
    locationNotificationAdd: 'Add notification',
    locationNotificationRemove: 'Remove notification',
    locationNotificationSeverity: 'Severity',
    locationNotificationSeverityDescription: 'Notification severity.',
    locationNotificationSeverityTypeWarning: 'Warning',
    locationNotificationSeverityTypeDanger: 'Danger',
    locationNotificationRemovalConfirmation:
      'Are you sure you want to remove the notification?',
    locationNotificationPlaceholder: 'Enter notification message',
    locationPlural: 'Locations',
    locationUpdated: 'The location is updated!',
    longitude: 'Longitude',
    gpxRoute: 'GPX route',
    groupclasses: 'Group classes',
    groups: 'Groups',
    groupType: 'Group type',
    groupTypes: 'Group types',
    groupTypeAdded: 'Group type added',
    groupTypeUpdated: 'Group type updated',
    groupTypeDeleted: 'Group type deleted',
    manualCheckinDeadline: 'Final date for manual check-ins',
    map: 'Map',
    maxAge: 'Max age',
    maxCheckInHelpText: 'Unlimited if no values are given',
    maxCheckInPerPerson: 'Max check in per person',
    maxCheckInPerTeam: 'Max check in per group',
    maxPerDay: 'Max pr. day',
    maxPerWeek: 'Max pr. week',
    maxTeamSize: 'Max group size',
    mypage: 'My page',
    minAge: 'Min age',
    minTeamSize: 'Min. group size',
    newGroupType: 'New group type',
    newLocation: 'New location',
    newParticipant: 'New participant',
    newTeam: 'New group',
    noContestTitle: 'No contest is selected',
    noContestSubtitle: 'Click on the search icon to select a contest.',
    optimizeTeamSize: 'Optimize group size',
    parentGroupType: 'Parent Group Type',
    parentOnly: 'Can not be selected (parent group only)',
    participant: 'Participant',
    participants: 'Participants',
    placement: 'Placement',
    points: 'Points',
    pointsChangeWarning:
      'NB: a change in points will have retroactive consequences',
    pointsForGymCheckIn: 'Points for gym check-ins',
    precountBooking: 'Precount of bookings after start date',
    radiusForCheckIn: 'Radius for check in',
    rememberCountryCode: 'NB: remember country code!',
    searchAdminUsers: 'Search users',
    searchGroups: 'Search groups',
    searchGroupTypes: 'Search group types',
    searchLocations: 'Search locations',
    searchParticipants: 'Search participants',
    searchTransactions: 'Search transactions',
    searchTPUsers: 'Search for Trimpoeng users',
    searchTPUsersPlaceholder: 'Write a name, phone number or email address',
    sendSMSEmail: 'Send SMS/Email',
    sponsorWebsiteUrl: 'Sponsor address',
    sponsorImage: 'Sponsor image',
    team: 'Group',
    teams: 'Groups',
    teamCreated: 'The group is created!',
    teamUpdated: 'The group is updated!',
    transaction: 'Transaction',
    transactions: 'Transactions',
    updateLocation: 'Update location',
    updateTeam: 'Update group',
    useMyPage: 'Use "My page"',
    useUserIdFrom: 'Use user id from',
    validBookingStatus: 'Valid booking status',
    waitTimeHelpText: 'Unlimited if no values are given',
    websiteType: 'Website type'
  }
});
contest.setLanguage(selectedLanguage);
export { contest };

const errorMessages = new LocalizedStrings({
  nb: {
    checkInNotAllowed: 'Det er for kort tid siden forrige innsjekk',
    contestNotOpen: 'Konkurransen er ikke åpen',
    failedToFetchContest: 'Det oppstod en feil ved henting av konkurranse',
    genericError: 'Det oppstod en feil',
    locationClosed: 'Turmålet er stengt',
    locationHasCheckIns: 'Kan ikke slette et turmål med innsjekk',
    locationInactive: 'Turmålet er inaktivt',
    locationMissing: 'Kunne ikke finne turmålet',
    locationNotOpen: 'Turmålet er ikke åpnet for innsjekk',
    missingFields: 'Mangler påkrevde felt',
    noParticipation: 'Ingen deltakelse funnet for denne deltakeren.',
    noUserForPhoneNumber: 'Ingen bruker ble funnet for dette nummeret',
    organizerExists: 'En arrangør med dette navnet finnes fra før',
    contestNameIdExists: 'En konkurranse med samme navn finnes fra før av',
    participantNotPaid: 'Deltakeren har ikke betalt avgiften'
  },
  en: {
    checkInNotAllowed: 'Too soon since the last check-in',
    contestNotOpen: 'The contest is not open',
    failedToFetchContest: 'An error occured while fetching contest',
    genericError: 'An error occured',
    locationClosed: 'The location is closed',
    locationHasCheckIns: 'Cannot delete a location with check-ins',
    locationInactive: 'The location is inactive',
    locationMissing: 'The location does not exist',
    locationNotOpen: 'The location is not open for check-ins',
    missingFields: 'Missing required fields',
    noParticipation: 'No participation found for this participant',
    noUserForPhoneNumber: 'No user was found for this phone number',
    organizerExists: 'An organizer with this name already exists',
    contestNameIdExists: 'A contest with the same name already exists',
    participantNotPaid: 'The participant have not paid the fee'
  }
});
errorMessages.setLanguage(selectedLanguage);
export { errorMessages };

const fileUpload = new LocalizedStrings({
  nb: {
    click: 'klikk',
    dropHelpText: 'Dra filer hit, eller',
    dropImageHelpText: 'Dra bilde hit, eller klikk for å velge bilde.',
    gpxCardContent:
      'GPX-filen er lastet opp. Du må nå velge "Legg til" for at den skal bli lagt til som ruteforslag. All tidligere ruteinfo vil bli slettet. Det ble totalt lagt til <points> punkter på ruten som har en lengde på <routeLength> meter.',
    gpxCardRouteExists:
      'Det finnes en rute med <points> punkter som allerede er lagret.',
    gpxCardSaveLength: 'Lagre lengden',
    gpxCardUseRoute: 'Bruk rutens siste punkt som turmål',
    gpxHelpText: 'Dra GPX-filen hit, eller klikk for å velge fil.',
    imageDeleteConfirmation: 'Ønsker du å slette dette bildet?',
    imageDeleteError: 'Det oppstod en feil under sletting av bildet',
    imageDeleteSuccess: 'Bildet ble slettet!',
    imageUploadError: 'En feil har oppstått under opplasting',
    imageUploadSuccess: 'Bildet har blitt lastet opp!',
    invalidFile: 'Ugyldig fil eller format',
    remove: 'Fjern',
    removeAll: 'Fjern alle',
    selectFiles: 'Velg filer',
    toSelect: 'for å velge',
    upload: 'Last opp'
  },
  en: {
    click: 'click',
    dropHelpText: 'Drop files here, or',
    dropImageHelpText: 'Drop image here, or click to select image.',
    gpxCardContent:
      'The GPX-file is uploaded. You must now click "Add" so that the route can be added. All previous route data will be deleted. It was added <points> points to this route with a length of <routeLength> meters.',
    gpxCardRouteExists: 'A route with <points> points already exists.',
    gpxCardSaveLength: 'Save length',
    gpxCardUseRoute: "Use the route's last point as location",
    gpxHelpText: 'Drop the GPX file here, or click to select file.',
    imageDeleteConfirmation: 'Ønsker du å slette dette bildet?',
    imageDeleteError: 'An error occured while deleting the image',
    imageDeleteSuccess: 'The image was deleted!',
    imageUploadError: 'An error occured while uploading the image',
    imageUploadSuccess: 'The image has been uploaded!',
    invalidFile: 'Invalid file or format',
    remove: 'Remove',
    removeAll: 'Remove all',
    selectFiles: 'Select files',
    toSelect: 'to select',
    upload: 'Upload'
  }
});
fileUpload.setLanguage(selectedLanguage);
export { fileUpload };

const filterModal = new LocalizedStrings({
  nb: {
    activities: 'Aktiviteter',
    dateFor: 'Dato for',
    differentLocations: 'Ulike antall turmål',
    numberOfCheckIns: 'Antall innsjekk',
    numberOfPoints: 'Antall poeng',
    registration: 'Påmelding',
    showCompanyTeams: 'Vis bedriftslag',
    showFamilyTeams: 'Vis familielag',
    showFriendTeams: 'Vis vennelag',
    showMembers: 'Vis medlemmer',
    showNonMembers: 'Vis ikke-medlemmer',
    showSinglePerson: 'Vis enkeltpersoner'
  },
  en: {
    activities: 'Activities',
    dateFor: 'Date for',
    differentLocations: 'Unique number of locations',
    numberOfCheckIns: 'Number of check-ins',
    numberOfPoints: 'Number of points',
    registration: 'Registration',
    showCompanyTeams: 'Show company teams',
    showFamilyTeams: 'Show family teams',
    showFriendTeams: 'Show friend teams',
    showMembers: 'Show members',
    showNonMembers: 'Show non-members',
    showSinglePerson: 'Show single persons'
  }
});
filterModal.setLanguage(selectedLanguage);
export { filterModal };

const helpTexts = new LocalizedStrings({
  nb: {
    clickToSeeCheckIns: 'Klikk for å se innsjekk',
    delete: 'Ønsker du å slette'
  },
  en: {
    clickToSeeCheckIns: 'Click to see check-ins',
    delete: 'Do you wish to delete'
  }
});
helpTexts.setLanguage(selectedLanguage);
export { helpTexts };

const logIn = new LocalizedStrings({
  nb: {
    createUser: 'Opprett bruker',
    fromTp1:
      'Du har nå blitt videresendt til den nye administrasjonssiden for Trimpoeng. Vi håper du liker den!',
    logIn: 'Logg inn',
    signInText: 'Vennligst logg inn for å bruke administrasjonssiden.',
    termsReadAndAccepted: 'Jeg har lest og akseptert vilkårene',
    userRequestText:
      'Du har havnet her fordi noen har lagt deg til som administrator. Hvis du har en Trimpoeng-bruker fra før av kan du logge inn for å bekrefte forespørselen. Har du ikke en Trimpoeng-bruker må du opprette en ny bruker.',
    welcome: 'Velkommen!'
  },
  en: {
    createUser: 'Create user',
    fromTp1:
      "You've now been redirected to the new administration page for Trimpoeng. We hope you like it!",
    logIn: 'Log in',
    signInText: 'Please log in to use the administration page.',
    termsReadAndAccepted: 'I have read and accepted the terms',
    userRequestText:
      "You've been sent here because someone added you as an administrator. If you already have a Trimpoeng user you can just log in to approve the request. If not, you must create a user before approving the request.",
    welcome: 'Welcome!'
  }
});
logIn.setLanguage(selectedLanguage);
export { logIn };

const mapModal = new LocalizedStrings({
  nb: {
    choosePosition: 'Velg posisjon for turmålet',
    dragMarker:
      'Klikk i kartet for å tegne ruten. Dra eksisterende markører for å endre ruten.',
    dragToChangeRadius:
      'Dra i de hvite punktene på sirkelen for å endre radius.',
    routeMode: 'Rutemodus',
    searchPlaceholder: 'Søk etter sted'
  },
  en: {
    choosePosition: 'Choose the posision for the location',
    dragMarker:
      'Click within the map to draw the route. Drag existing marker to change route.',
    dragToChangeRadius: 'Drag the white dots on the sircle to change radius.',
    routeMode: 'Route mode',
    searchPlaceholder: 'Search for location'
  }
});
mapModal.setLanguage(selectedLanguage);
export { mapModal };

const moveParticipantModal = new LocalizedStrings({
  nb: {
    noTeam: 'Ingen gruppe'
  },
  en: {
    noTeam: 'No team'
  }
});
moveParticipantModal.setLanguage(selectedLanguage);
export { moveParticipantModal };

const participant = new LocalizedStrings({
  nb: {
    activity: 'Aktivitet',
    address: 'Adresse',
    assignCode: 'Tildel kode',
    birthYear: 'Fødselsår',
    countryCode: 'Landskode',
    activityDeleted: 'Aktiviteten er slettet.',
    activityDeleteError: 'Det oppstod en feil under sletting av aktiviteten.',
    checkInDeleted: 'Innsjekket er slettet.',
    checkInDeleteError: 'Det oppstod en feil under sletting av innsjekk',
    checkInPoints: 'Innsjekkspoeng',
    createParticipant: 'Opprett deltaker',
    deleteActivity:
      'Ønsker du å fjerne <name> - <date> fra deltakeren? Dette kan ikke gjøres om.',
    deleteCheckIn:
      'Ønsker du å fjerne <location> - <date> fra deltakeren? Dette kan ikke gjøres om.',
    existingParticipant: 'En deltaker med dette telefonnummeret finnes fra før',
    extraPoints: 'Ekstrapoeng',
    extraPointsRegisteredText: 'Ekstrapoeng er registrert.',
    extraPointsRegistrationError: 'Det oppstod en feil ved tildeling av poeng',
    female: 'Kvinne',
    filtering: 'Filtrering',
    firstName: 'Fornavn',
    gender: 'Kjønn',
    giveExtraPoints: 'Gi ekstrapoeng',
    lastActive: 'Sist aktiv',
    lastName: 'Etternavn',
    male: 'Mann',
    manualCheckIn: 'Manuelt innsjekk',
    manualCheckInRegisteredText: 'Innsjekk er registrert.',
    manualCheckInError: 'Det oppstod en feil registrering av innsjekk',
    member: 'Medlem',
    membership: 'Medlemskap',
    moveParticipantError: 'Det oppstod en feil under flytting av deltakeren(e)',
    moveParticipantSuccess: 'Flyttet deltaker(e)',
    paidAmount: 'Betalt beløp',
    participantCode: 'Deltakerkode',
    participantCodeError: 'Det oppstod en feil ved tildeling av deltakerkode',
    participantCodeModalText:
      'Er du sikker på at du vil tildele en deltakerkode til denne deltakeren? Deltakeren vil da få tilgang til konkurransen uten å betale.',
    participantCodeSuccess: 'Deltakeren har fått tildelt en kode.',
    participantCreated: 'Deltakeren er opprettet!',
    participantCreatedError:
      'Det oppstod en feil under opprettelsen av deltakeren',
    participantDeleted: 'Deltakeren er fjernet',
    participantDeletedError: 'Det oppstod en feil under fjerning av deltakeren',
    phone: 'Telefon',
    phoneNumber: 'Telefonnummer',
    profileImage: 'Profilbilde',
    registered: 'Påmeldt',
    registeredDate: 'Påmeldt dato',
    registeredFor: 'Påmeldt på',
    registeredFrom: 'Registrert fra',
    registeredOn: 'Registrert på',
    removeFromContestText:
      'Ønsker du å fjerne <userName> fra <contestName>? Husk at alle poeng mistes.',
    surname: 'Etternavn',
    totalPoints: 'Totalpoeng',
    trips: 'Turer',
    uniqueTrips: 'Ulike turer',
    username: 'Brukernavn'
  },
  en: {
    activity: 'Activity',
    address: 'Address',
    assignCode: 'Assign code',
    birthYear: 'Birth year',
    countryCode: 'Country code',
    activityDeleted: 'The activity is deleted.',
    activityDeleteError: 'An error occured while deleting the activity.',
    checkInDeleted: 'The check-in is deleted.',
    checkInDeleteError: 'An error occured while deleting the check-in',
    checkInPoints: 'Check-in points',
    createParticipant: 'Create participant',
    deleteActivity:
      'Do you wish to remove <name> - <date> from the participant? This action cannot be undone.',
    deleteCheckIn:
      'Do you wish to remove <location> - <date> from the participant? This action cannot be undone.',
    existingParticipant: 'A participant with this phone number already exists',
    extraPoints: 'Extra points',
    extraPointsRegisteredText: 'Extra points are registered.',
    extraPointsRegistrationError:
      'An error occured while assigning extra points',
    female: 'Female',
    filtering: 'Filtering',
    firstName: 'First name',
    gender: 'Gender',
    giveExtraPoints: 'Give extra points',
    lastActive: 'Last active',
    lastName: 'Last name',
    male: 'Male',
    manualCheckIn: 'Manual check-in',
    manualCheckInRegisteredText: 'Check-in is registered.',
    manualCheckInError: 'An error occured while registering the check-in',
    member: 'Member',
    membership: 'Membership',
    moveParticipantError: 'An error occured while moving the participant(s)',
    moveParticipantSuccess: 'Moved participant(s)',
    paidAmount: 'Paid amount',
    participantCode: 'Participant code',
    participantCodeError:
      'An error occurred while assigning a participant code',
    participantCodeModalText:
      'Are you sure you wish to assign a participant code to this participant? The participant will then get access to the contest without paying.',
    participantCodeSuccess: 'The participant have been assigned a new code',
    participantCreated: 'The participant is created!',
    participantCreatedError: 'An error occured while creating the participant',
    participantDeleted: 'The participant is removed',
    participantDeletedError: 'An error occured while removing the participant',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    profileImage: 'Profile image',
    registered: 'Registered',
    registeredDate: 'Registered date',
    registeredFor: 'Registered for',
    registeredFrom: 'Registered from',
    registeredOn: 'Registered on',
    removeFromContestText:
      'Do you wish to remove <userName> from <contestName>? Remember that all points will be lost.',
    surname: 'Surname',
    totalPoints: 'Total points',
    trips: 'Trips',
    uniqueTrips: 'Unique trips',
    username: 'Username'
  }
});
participant.setLanguage(selectedLanguage);
export { participant };

const priceDeleteModal = new LocalizedStrings({
  nb: {
    content: 'Ønsker du å slette denne prisen?'
  },
  en: {
    content: 'Do you wish to delete this price?'
  }
});
priceDeleteModal.setLanguage(selectedLanguage);
export { priceDeleteModal };

const promotion = new LocalizedStrings({
  nb: {
    tryAppText: 'Prøv appen!'
  },
  en: {
    tryAppText: 'Try the app!'
  }
});
promotion.setLanguage(selectedLanguage);
export { promotion };

const resetPasswordModal = new LocalizedStrings({
  nb: {
    emailGenericError: 'Det oppstod en feil ved utsending av epost',
    missingEmailError: 'Deltakeren mangler epostadresse',
    missingPhoneError: 'Deltakeren mangler telefonnnummer',
    modalBodyText:
      'Her kan du nullstille passordet til denne deltakeren. Du vil da få opp en firesifret kode som du kan videreformidle til deltakeren. Du kan også velge om du vil sende ut en epost og/eller en SMS med det nye passordet.',
    newPasswordText: 'Det nye passordet er',
    resetError: 'Det oppstod en feil ved nullstilling av passord',
    sendEmail: 'Send passord per epost',
    sentEmail: 'Sendt per epost til',
    sendSMS: 'Send passord per SMS (belasted arrangør ihht. avtale)',
    sentSMS: 'Sendt per SMS til',
    smsGenericError: 'Det oppstod en feil ved utsending av sms'
  },
  en: {
    emailGenericError: 'An error occured while sending the email',
    missingEmailError: 'The contestant is missing an email address',
    missingPhoneError: 'The contestant is missing a phone number',
    modalBodyText:
      'Here you can reset the password for this contestant. You will then be shown a four digit code that you can convey to the participant. You can also choose if you wish to send an email and/or an SMS with the new password.',
    newPasswordText: 'The new password is',
    resetError: 'An error occured while resetting the password',
    sendEmail: 'Send password as email',
    sentEmail: 'Sendt email to',
    sendSMS:
      'Send password as SMS (charges contest organizer according to agreement)',
    sentSMS: 'Sendt SMS to',
    smsGenericError: 'An error occured while sending sms'
  }
});
resetPasswordModal.setLanguage(selectedLanguage);
export { resetPasswordModal };

const searchDrawer = new LocalizedStrings({
  nb: {
    ended: 'Avsluttet',
    notStarted: 'Ikke startet',
    ongoing: 'Pågående',
    searchContests: 'Søk konkurranser',
    showEndedContests: 'Vis avsluttede konkurranser'
  },
  en: {
    ended: 'Ended',
    notStarted: 'Not started',
    ongoing: 'Ongoing',
    searchContests: 'Search contests',
    showEndedContests: 'Show ended contests'
  }
});
searchDrawer.setLanguage(selectedLanguage);
export { searchDrawer };

const settings = new LocalizedStrings({
  nb: {
    aboutContest: 'Om konkurransen',
    advancedSettings: 'Avanserte innstillinger',
    adminUsers: 'Brukere',
    appImage: 'App - startbilde',
    automaticSMS: 'Automatiske SMS',
    contactInfo: 'Kontaktinformasjon',
    disabledContactTrimpoeng:
      'Ta kontakt med Trimpoeng for å endre denne innstillingen.',
    contestTermsSubtitle: 'Hvis utfylt må alle deltakere godta vilkårene',
    copyLocations: 'Kopier turmål',
    copyOldContest: 'Kopier en gammel konkurranse',
    emailLogo: 'Logo for epost',
    extraInfo: 'Ekstra informasjon under konkurransenavn',
    frontPageImage: 'Forsidebilde',
    mainColor: 'Hovedfarge',
    organizer: 'Arrangør',
    organizerName: 'Arrangørnavn',
    priceCreated: 'Prisen er opprettet!',
    priceDeleted: 'Prisen er slettet',
    priceUpdated: 'Prisen er oppdatert!',
    settingsUpdated: 'Innstillingene er oppdatert!',
    showHideAdvanced: 'Vis/skjul avanserte innstillinger',
    showMainImageWithoutText: 'Vis hovedbilde uten tekst',
    socialMedia: 'Sosiale medier',
    website: 'Nettside',
    websiteLogo: 'Logo for nettside'
  },
  en: {
    aboutContest: 'About the contest',
    adminUsers: 'Users',
    advancedSettings: 'Advanced settings',
    appImage: 'App - start image',
    automaticSMS: 'Automatic SMS',
    contactInfo: 'Contact information',
    disabledContactTrimpoeng: 'Contact Trimpoeng to change this setting.',
    contestTermsSubtitle:
      'If filled out, all participants must accept these terms',
    copyLocations: 'Copy locations',
    copyOldContest: 'Copy an old contest',
    emailLogo: 'Logo for email',
    extraInfo: 'Extra information under contest name',
    frontPageImage: 'Front page image',
    mainColor: 'Main color',
    organizer: 'Organizer',
    organizerName: 'Organizer name',
    priceCreated: 'The price is created!',
    priceDeleted: 'The price is deleted',
    priceUpdated: 'The price is updated!',
    settingsUpdated: 'The settings are updated!',
    showHideAdvanced: 'Show/hide advanced settings',
    showMainImageWithoutText: 'Show main image without text',
    socialMedia: 'Social media',
    website: 'Website',
    websiteLogo: 'Logo for website'
  }
});
settings.setLanguage(selectedLanguage);
export { settings };

const smsEmailModal = new LocalizedStrings({
  nb: {
    criticalInformation:
      'Dette er tjenestekritisk informasjon. Send også til deltakere som ikke har samtykket til kommunikasjon.',
    errorMessage: 'Det oppstod en feil ved sending',
    message: 'Melding',
    noMessage: 'Du har ikke skrevet en beskjed.',
    recipient: 'Mottaker',
    recipients: 'Mottakere',
    sentMessageTo: 'Din melding har blitt sendt til',
    subject: 'Emne'
  },
  en: {
    criticalInformation:
      'This is critical information. Send also to participants who have not consented to communication.',
    errorMessage: 'An error occured while sending the message',
    message: 'Message',
    noMessage: 'You have not written a message',
    recipient: 'Recipient',
    recipients: 'Recipients',
    sentMessageTo: 'Your message have been sent to',
    subject: 'Subject'
  }
});
smsEmailModal.setLanguage(selectedLanguage);
export { smsEmailModal };

const userModal = new LocalizedStrings({
  nb: {
    editUserInfo: 'Rediger brukerinfo',
    updateError: 'Det oppstod en feil ved oppdatering av brukeren',
    updateSuccess: 'Brukeren har blitt oppdatert!'
  },
  en: {
    editUserInfo: 'Edit user info',
    updateError: 'An error occured while updating the user',
    updateSuccess: 'The user has been updated!'
  }
});
userModal.setLanguage(selectedLanguage);
export { userModal };

const validation = new LocalizedStrings({
  nb: {
    contestName: 'Konkurransenavn er påkrevd',
    countrycode: 'Landskode er påkrevd',
    date: 'Dato er påkrevd',
    email: 'Epost er påkrevd',
    firstName: 'Fornavn er påkrevd',
    invalidDate: 'Ugyldig dato',
    invalidEmail: 'Ugyldig epostadresse',
    lastName: 'Etternavn er påkrevd',
    latitude: 'Breddegrad er påkrevd',
    location: 'Turmål er påkrevd',
    longitude: 'Lengdegrad er påkrevd',
    name: 'Navn er påkrevd',
    organizer: 'Arrangør er påkrevd',
    password: 'Passord er påkrevd',
    phone: 'Telefonnummer er påkrevd',
    points: 'Poeng er påkrevd',
    price: 'Pris er påkrevd',
    radius: 'Radius er påkrevd',
    surname: 'Etternavn er påkrevd',
    termsAccepted: 'Du må lese og akseptere vilkårene'
  },
  en: {
    contestName: 'Contest name is required',
    countrycode: 'Country code is required',
    date: 'Date is required',
    email: 'Email is required',
    firstName: 'First name is required',
    invalidDate: 'Invalid date',
    invalidEmail: 'Invalid email address',
    lastName: 'Last name is required',
    latitude: 'Latitude is required',
    location: 'Location is required',
    longitude: 'Longitude is required',
    name: 'Name is required',
    organizer: 'Organizer is required',
    password: 'Password is required',
    phone: 'Phone number is required',
    points: 'Points are required',
    price: 'Price is required',
    radius: 'Radius is required',
    surname: 'Surname is required',
    termsAccepted: 'You must read and accept the terms'
  }
});
validation.setLanguage(selectedLanguage);
export { validation };

const transaction = new LocalizedStrings({
  nb: {
    cartSum: 'SUM',
    cartItemName: 'Tekst',
    cartItemQuantity: 'Antall',
    cartItemPrice: 'Pr. stk.',
    cartItemSum: 'Totalt',
    transactionUserName: 'Kjøper',
    transactionStatus: 'Status',
    transactionAmount: 'Beløp',
    transactionLastUpdated: 'Endret'
  },
  en: {
    cartSum: 'SUM',
    cartItemName: 'Text',
    cartItemQuantity: 'Quantity',
    cartItemPrice: 'Per item',
    cartItemSum: 'Total',
    transactionUserName: 'Buyer',
    transactionStatus: 'Status',
    transactionAmount: 'Amount',
    transactionLastUpdated: 'Updated'
  }
});
transaction.setLanguage(selectedLanguage);
export { transaction };

const adminUsers = new LocalizedStrings({
  nb: {
    accessLevel: 'Tilgangsnivå',
    accessLevel100: 'Konkurranse',
    accessLevel200: 'Arrangør',
    accessLevel300: 'Bedrift',
    accessLevelExplanation:
      'Brukeren kan få tilgang til denne konkurransen, alle konkurransene til denne arrangøren eller alle konkurransene til denne bedriften.',
    addUserExplanation:
      'Det vil bli sendt en e-post til den e-postadressen du oppgir her med en aktiveringslenke i. Den nye adminbrukeren må klikke på lenken og logge inn eller opprette en ny bruker.',
    adminUsers: 'Brukere',
    logInAndConfirm: 'Logg inn og bekreft',
    createUserAndConfirm: 'Opprett bruker og bekreft',
    user: 'bruker',
    userAdded:
      'En forespørsel har blitt sendt til %EMAIL%. Når vedkommende eventuelt godtar forespørselen vil brukeren dukke opp her.',
    userCreated: 'Bruker opprettet',
    userCreatedText:
      'Brukeren din er opprettet og du vil i løpet av kort tid motta en SMS og en e-post med brukernavn og passord. Klikk på «Logg inn» ovenfor og logg inn med brukeren din for å fullføre.',
    userCreationError: 'Det skjedde en feil ved forsøk på å legge til brukeren',
    userDeleted: 'Brukeren ble fjernet fra konkurransen'
  },
  en: {
    accessLevel: 'Access level',
    accessLevel100: 'Contest',
    accessLevel200: 'Contest Organizer',
    accessLevel300: 'Company',
    accessLevelExplanation:
      "The user will have access to either this contest, all of this organizer's contests or all of this company's contests.",
    addUserExplanation:
      'An email will be sent to the provided email address with an activation link.',
    adminUsers: 'Users',
    logInAndConfirm: 'Log in and confirm',
    createUserAndConfirm: 'Create user and confirm',
    user: 'user',
    userAdded:
      'A request has been sent to %EMAIL%. Once it has been approved, the user will be displayed in this list',
    userCreated: 'User created',
    userCreatedText:
      'Your user has been created. You will shortly receive an SMS and an email with your username and password. Click «Log in» above and log in to approve the request.',
    userCreationError: 'An error occured when trying to add the user',
    userDeleted: 'The user was removed from the contest'
  }
});
adminUsers.setLanguage(selectedLanguage);
export { adminUsers };
