import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItem,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Search as SearchIcon,
  XCircle as XIcon,
  RefreshCw as RefreshIcon,
  Clock as ClockIcon,
  Flag as FlagIcon
} from 'react-feather';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDispatch, useSelector } from 'src/store';
import { getContests } from 'src/slices/contest';
import { common, searchDrawer } from 'src/assets/locale';
import useSettings from 'src/hooks/useSettings';

const applyFilter = (contests, query, showEnded) => {
  return contests.filter(contest => {
    let matches = true;
    const isEnded = moment.utc(contest.dateTo).isBefore(moment.utc());

    if (query) {
      const matchName =
        contest.name.toLowerCase().includes(query.toLowerCase()) ||
        contest.contestOrganizer.name
          .toLowerCase()
          .includes(query.toLowerCase());

      matches = showEnded ? matchName : matchName && !isEnded;
    } else if (!showEnded) {
      matches = !isEnded;
    }

    return matches;
  });
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  listWrapper: {
    height: '70vh',
    width: 450
  },
  listElement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  contestName: {
    maxWidth: '10em'
  },
  statusRed: {
    color: theme.palette.error.main
  },
  statusOrange: {
    color: theme.palette.warning.main
  },
  statusGreen: {
    color: theme.palette.success.main
  }
}));

const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contests, isFetching } = useSelector(state => state.contest);
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [showEndedContests, setEndedContests] = useState(false);
  const { saveSettings } = useSettings();

  const filteredContests = applyFilter(contests, query, showEndedContests);

  const handleOpen = () => {
    setOpen(true);
    if (!contests || contests.length === 0) {
      dispatch(getContests());
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckbox = (event, value) => {
    setEndedContests(value);
  };

  const handleSelectContest = contest => {
    saveSettings({ selectedContest: contest });
    setOpen(false);
    history.push('/app');
  };

  const renderIconRow = contest => {
    let color;
    let icon;
    let text;

    const from = moment.utc(contest.dateFrom);
    const to = moment.utc(contest.dateTo);
    const now = moment.utc();

    if (
      contest.dateFrom &&
      contest.dateTo &&
      from.isBefore(now) &&
      to.isAfter(now)
    ) {
      color = classes.statusGreen;
      icon = <RefreshIcon />;
      text = searchDrawer.ongoing;
    } else if (contest.dateFrom && from.isAfter(now)) {
      color = classes.statusOrange;
      icon = <ClockIcon />;
      text = searchDrawer.notStarted;
    } else {
      color = classes.statusRed;
      icon = <FlagIcon />;
      text = searchDrawer.ended;
    }

    return (
      <Box mt={1} display="flex" flexDirection="row">
        <SvgIcon className={clsx(color)} fontSize="small">
          {icon}
        </SvgIcon>
        <Box ml={1} />
        <Typography className={clsx(color)} variant="body2">
          {text}
        </Typography>
      </Box>
    );
  };

  const renderListItemRow = props => {
    const { index, style } = props;
    const contest = filteredContests[index];

    return contest ? (
      <div key={index} style={style}>
        <ListItem
          className={classes.listElement}
          button
          onClick={() => handleSelectContest(contest)}
        >
          <Box display="flex" flexDirection="column">
            <Typography noWrap className={classes.contestName}>
              {contest.name}
            </Typography>
            {renderIconRow(contest)}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography noWrap align="right" className={classes.contestName}>
              {contest.contestOrganizer.name}
            </Typography>
            <Typography align="right" variant="body2" color="textSecondary">
              {contest.dateFrom
                ? moment.utc(contest.dateFrom).format('DD.MM.YY')
                : ''}
              {' - '}
              {contest.dateTo
                ? moment.utc(contest.dateTo).format('DD.MM.YY')
                : ''}
            </Typography>
          </Box>
        </ListItem>
        <Divider />
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Tooltip title={common.search}>
        <IconButton color="inherit" onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                {common.search}
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setQuery(event.target.value)}
                placeholder={searchDrawer.searchContests}
                value={query}
                variant="outlined"
              />
            </Box>
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showEndedContests}
                    onChange={handleCheckbox}
                    value={showEndedContests}
                  />
                }
                label={searchDrawer.showEndedContests}
              />
            </Box>
            <Box mt={4}>
              {isFetching ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <div className={classes.listWrapper}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <FixedSizeList
                        itemCount={filteredContests.length}
                        height={height}
                        width={width}
                        itemSize={80}
                      >
                        {renderListItemRow}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                </div>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
