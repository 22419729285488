import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const defaultFilterValues = {
  numberOfCheckIns: {
    comparator: '=',
    value: null
  },
  differentLocations: {
    comparator: '=',
    value: null
  },
  numberOfPoints: {
    comparator: '=',
    value: null
  },
  showNonMembers: false,
  showMembers: false,
  showCompanyTeams: false,
  showFriendTeams: false,
  showFamilyTeams: false,
  showSinglePerson: false,
  isFilterActive: false
};

const initialState = {
  groups: [],
  groupTypes: [],
  selectedGroup: null,
  filterValues: { ...defaultFilterValues },
  isFetching: false
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    getGroupTypes(state, action) {
      const { groupTypes } = action.payload;

      state.groupTypes = groupTypes;
    },
    getGroups(state, action) {
      const { groupTypes } = action.payload;

      const groups = [];

      groupTypes.forEach(type => {
        type.groups.forEach(group => {
          groups.push({
            id: group.id,
            parentOnly: group.parentOnly,
            parentGroupId: group.parentGroupId,
            groupTypeId: group.groupTypeId,
            name: group.name,
            rank: group.ranking?.ranking || '-',
            type: type.name,
            participants: group.participations.length,
            trips: group.ranking?.totalCheckIns || 0,
            uniqueTrips: group.ranking?.totalUniqueLocations || 0,
            points: group.ranking?.totalPoints || 0
          });
        });
      });

      groups.sort((a, b) => {
        if (a.points < b.points) {
          return 1;
        }

        if (a.points > b.points) {
          return -1;
        }

        return 0;
      });

      state.groups = groups;
      state.groupTypes = groupTypes;
    },
    setGroups(state, action) {
      const { groups } = action.payload;

      state.groups = groups;
    },
    setSelectedGroup(state, action) {
      const { group } = action.payload;

      state.selectedGroup = group;
    },
    setFilterValues(state, action) {
      const { filterValues } = action.payload;
      const updated = { ...state.filterValues, ...filterValues };

      state.filterValues = updated;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getGroups = contestId => async dispatch => {
  const query = `
    {
      contest{
        groupTypes{
          id,
          name,
          groups{
            id,
            name,
            groupTypeId,
            ranking {
              totalPoints,
              totalCheckIns,
              totalLength,
              totalActivities,
              totalUniqueLocations,
              ranking
            },
            parentOnly,
            parentGroupId,
            participations{
              id
            }
          }
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getGroups(response.data.data.contest));
};

export const setSelectedGroup = group => async dispatch => {
  dispatch(slice.actions.setSelectedGroup({ group }));
};

export const setFilterValues = filterValues => async dispatch => {
  dispatch(slice.actions.setFilterValues({ filterValues }));
};

export const resetFilterValues = () => async dispatch => {
  dispatch(
    slice.actions.setFilterValues({ filterValues: defaultFilterValues })
  );
};

export const clearGroups = () => async dispatch => {
  dispatch(slice.actions.setGroups({ groups: [] }));
};

export default slice;
